.App {
  background-color: #282c34;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-container {
  min-height: 100vh;
  max-width: 1000px;
}

.App-header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*========== My stuff ==========*/

.avatar-crop {
  width: 230px;
  height: 230px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.avatar {
  display: inline;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

/*------- Page Specific -------*/

.home-section {
  width: 100%;
  padding: 20px;
}

.home-item {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.primary-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.primary-item-name {
  margin-bottom: 10px;
  font-weight: 400;
  min-width: 300px;
}

.primary-item-right {
  margin-bottom: 10px;
  font-weight: 700;
}

/*------- Containers -------*/

.list-row {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.list-row-item {
  margin: 0px 20px 0px 20px;
}

.list-row-divider {
  width: 0;
  height: 14px;
  border: 1px solid #ffffff;
}

.card-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* background: rgb(238,174,202);
background: radial-gradient(circle, rgba(148,187,233,0.3181066176470589) 0%, rgba(255,255,255,0.0963979341736695) 80%, rgba(255,255,255,0.01) 100%); */

.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(108,172,255);
  background: linear-gradient(315deg, rgba(108,172,255,0.5) 0%, rgba(255,255,255,0.2) 100%);
  width: 260px;
  height: 58px;
  border-radius: 0.6em;
  padding: 15px;
  margin: 0.5em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
  height: 20px;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.icon-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

a.card-link {
  text-decoration: none;
} 


